import { appid, WX_LOGIN_REDIRECT_URL } from "config";
import qs from "query-string";

export default function login(originUrl?: string) {
  const redirect_url = qs.stringifyUrl({
    url: WX_LOGIN_REDIRECT_URL,
    query: {callBackUri: originUrl}  //callBackUri 是回调URL
  });
  console.log(encodeURIComponent(redirect_url));
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
      redirect_url
  )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
  console.log(url);
  window.location.href = url;
}