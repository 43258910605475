import * as crypto from "crypto";
//签名  SHA256加密
const shaSign = (params: any,signKey:string="") => {
  if (!params) return params;
  const keys = []; // 私钥
  if (!signKey)
    signKey = "hrcU2hLGYtOc5d45Ge03IyzHFzaai0fCRjiC";

  for (const k in params) {
    if (k === "remark" && typeof params[k] === "object") {
      keys.push(...Object.keys(params[k]));
    } else if (params[k] && k !== "sign") {
      keys.push(k);
    } else if (params[k] === 0 && k !== "sign") {
      keys.push(k);
    }
  }
  params = {
    key: signKey,
    ...params,
  };
  keys
      .sort((s, t) => {
        const a = s.toLowerCase();
        const b = t.toLowerCase();
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      })
      .push("key");
  let paramStr = "";
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i !== 0) {
      paramStr += "&";
    }
    if (
        params.remark &&
        typeof params.remark === "object" &&
        params.remark[key]
    ) {
      paramStr += key + "=" + params.remark[key];
    } else if (
        params[key] instanceof Array ||
        typeof params[key] === "object"
    ) {
      paramStr += key + "=" + JSON.stringify(params[key]);
    } else {
      paramStr += key + "=" + params[key];
    }
  }
  const sha256 = crypto.createHash("SHA256");
  sha256.update(paramStr);
  const sign = sha256.digest("hex").toUpperCase();
  delete params["key"];
  params.Sign = sign;
  return params;
};

export default shaSign;
