/** 身份证正则 */
export const IdCardReg = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;

// 是否为链接
// [\.[A-Za-z0-9]+]? .com可选（当使用本地localhost时候无）
// [:[0-9]{1,5}]? 域名后带端口，端口1到5位数字 （匹配格式localhost:3000）
export const urlReg = /^http(s)?:\/\/[A-Za-z0-9]+[\.[A-Za-z0-9]+]?[:[0-9]{1,5}]?[\/=\?%\-&_~`@[\]\’:+!]*([^<>\"\"])*$/;


// 是否手机号
export const mobileReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;