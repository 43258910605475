import React, { FC, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { queryBanner } from "src/service/api/Hospital";
import Skeleton from "@material-ui/lab/Skeleton";
import dayjs from "dayjs";

interface SwiperProps {
  className?: string;
}

type BannerType = {
  imageDesc: string;
  imageSeq: number;
  imageUrl: string;
};

/**
 *
 * @name 轮播组件
 */
const Swiper: FC<SwiperProps> = ({ className }) => {
  const [banner, setBanner] = useState<BannerType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      let imgs;
      try {
        const data = localStorage.getItem("home_img");
        const expires = localStorage.getItem("home_img_expires");
        if (data && expires) {
          // 是否过期
          const beOverdue = dayjs().isAfter(expires, "s");
          if (!beOverdue) {
            imgs = JSON.parse(data);
          }
        }
      } catch (error) {}
      if (imgs) {
        setBanner(imgs);
        return;
      }
      setLoading(true);
      // 暂时写死
      // const res = await queryBanner();
      const res = {"imagesList":[{"imageSeq":0,"imageDesc":"第1张首页图片","imageUrl": require('../../assets/logo.jpg')}],"thirdCode":0,"thirdMsg":"获取医院首页轮播图片列表",}
      setLoading(false);
      if (res) {
        setBanner(res.imagesList);
        localStorage.setItem("home_img", JSON.stringify(res.imagesList));
        localStorage.setItem(
          "home_img_expires",
          dayjs().add(1, "h").toString()
        );
      }
    }
    getData();
  }, []);

  return (
    <Slider arrows={false} autoplay dots className={className}>
      {loading ? (
        <Skeleton variant="rect" animation="wave" height={116} />
      ) : (
        banner.map((item) => (
          <img src={item.imageUrl} alt="" key={item.imageSeq} height={116} />
        ))
      )}
    </Slider>
  );
};

export default Swiper;
