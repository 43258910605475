import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { RouteProps, Route } from "react-router-dom";
import { RootState } from "src/reducers";
import { UserState } from "src/reducers/user";
import login from "src/utils/login";
import pkg from "../../../package.json";

const AuthRoute: FC<RouteProps> = (props) => {
  const user = useSelector<RootState, UserState>((state) => state.user);

  useEffect(() => {
    if (!user) {
      const originUrl = typeof props.path === "string" ? props.path : "/";
      login(originUrl);
    }
  }, [props.path, user]);

  useEffect(() => {
    window.localStorage.wmUserInfo = JSON.stringify({
      userId: user?.userid,
      projectVersion: pkg.version,
    });
  }, [user]);

  return user ? <Route {...props} /> : null;
};

export default AuthRoute;
