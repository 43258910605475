import {
  ADD_PATIENT,
  DEL_PATIENT,
  GET_PATIENT,
  PatientAction,
  UPDATE_PATIENT,
  SET_PATIENT,
} from "src/actions/patient";
import { PatientType } from "src/typings/patient";

export type PatientsState = {
  patients: PatientType[];
  fetching: boolean;
};

const initState: PatientsState = {
  patients: [],
  fetching: false,
};

const patientReducer = (
  state = initState,
  action: PatientAction
): PatientsState => {
  switch (action.type) {
    case GET_PATIENT:
      return { ...state, fetching: true };
    case SET_PATIENT:
      return { patients: action.payload ?? state.patients, fetching: false };
    case ADD_PATIENT:
      return { ...state, patients: state.patients.concat(action.payload) };
    case DEL_PATIENT:
      return {
        ...state,
        patients: state.patients.filter(
          (item) => item.patientid !== action.payload
        ),
      };
    case UPDATE_PATIENT:
      const index = state.patients.findIndex(
        (item) => item.idcard === action.payload.idcard
      );
      if (index === -1) return state;
      const newItem = { ...state.patients[index], ...action.payload };
      state.patients.splice(index, 1, newItem);
      return state;
    default:
      return state;
  }
};

export default patientReducer;
