import React, { FC, ReactNode } from "react";
import { Grid, makeStyles, Button, Theme } from "@material-ui/core";
import type { GridSize } from "@material-ui/core";
import { Link } from "react-router-dom";

interface NavMenuProps {
  title?: ReactNode;
  cols?: GridSize;
  navList: {
    label?: ReactNode;
    icon?: ReactNode;
    url?: string;
    future?: boolean;
    onClick?: () => void;
  }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.custom.bgc,
    boxShadow: "1px 8px 7px 0px rgba(0, 132, 236, 0.15);",
    padding: 16,
    borderRadius: 4,
    "& + &": {
      marginTop: 5,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 12,
  },
  item: {
    textAlign: "center",
    position: "relative",
    "& .MuiButton-label": {
      display: "block",
      color: "#494949",
      whiteSpace: "nowrap",
    },
  },
  future: {
    overflow: 'hidden',
    "&.MuiButtonBase-root::before": {
      top: "0%",
      right: "-84%",
      content: "'即将上线'",
      position: "absolute",
      transform: "rotate(45deg) scale(0.5)",
      background: "#e5eaff",
      width: "200%",
      color: '#333'
    },
  },
}));

/**
 *
 * @name 导航盒子
 * @param title 标题
 * @param cols 每项占据内容大小
 */
const NavMenu: FC<NavMenuProps> = ({ title, cols = 3, navList }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <Grid container>
        {navList.map((item, index) => (
          <Grid item xs={cols} className={classes.item} key={index}>
            <Button
              color={item.future ? "default" : "primary"}
              className={item.future ? classes.future : undefined}
              disabled={item.future}
              onClick={item.onClick}
              component={item.url ? Link : "button"}
              to={item.url}
            >
              <div>{item.icon}</div>
              <div>{item.label}</div>
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default NavMenu;

NavMenu.defaultProps = {
  cols: 3,
};
