import requestFn from "src/service";

// 微信正扫
export function wechatPayScan(data: any) {
  return requestFn("/Pay/cscanb", { method: "post", data });
}

// 微信支付
export function wechatPay(data: any) {
  return requestFn("/Pay/jsapipay", { method: "post", data });
}

// 查询微信支付订单
export function queryWechatOrder(params: any) {
  return requestFn("/Pay/query", { params });
}

// 关闭微信支付订单
export function closeWechatOrder(params: any) {
  return requestFn("/Pay/close", { params });
}

// 撤销微信支付订单
export function cancelWechatOrder(data: any) {
  return requestFn("/Pay/cancel", { method: "post", data });
}

// 申请微信支付订单退款
export function refundWechatOrder(data: any) {
  return requestFn("/Pay/refund", { method: "post", data });
}

// 查询微信退款信息
export function queryWechatRefund(params: any) {
  return requestFn("/Pay/refundquery", { params });
}

// his支付成功返回数据
export function updateWechatOrder(data: any) {
  return requestFn("/Pay/updatestatus", { method: "post", data });
}

// 微信code换取个人信息
export function getWechatUser(params: any) {
  return requestFn("/WeChatOfficialAccount/web_token", {
    params,
  });
}

// 获取微信凭证
export function getWechatTicket(params: any) {
  return requestFn("/WeChatOfficialAccount/jsapi_ticket", {
    params,
  });
}
