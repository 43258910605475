import React, { FC } from "react";
import { SvgIcon } from "@material-ui/core";
import type { SvgIconProps } from "@material-ui/core";

interface CustomIconProps extends SvgIconProps {
  type: string;
  [key: string]: any;
}

/**
 * @name 自定义图标
 */
const CustomIcon: FC<CustomIconProps> = ({ type, ...rest }) => {
  return (
    <SvgIcon {...rest}>
      <use xlinkHref={`#${type}`}></use>
    </SvgIcon>
  );
};

export default CustomIcon;
