import { LOGIN, loginAction } from "src/actions/user";

export type UserState = {
  access_token: string;
  openid: string;
  userid: number;
} | null;

let initState: UserState = null;

const userReducer = (state = initState, action: loginAction): UserState => {
  if (action.type === LOGIN) {
    return {
      ...action.payload,
    };
  }
  return state;
};

export default userReducer;
