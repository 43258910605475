import React, { FC, useMemo, useState } from "react";
import Swiper from "src/components/Swiper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Theme,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import NavMenu from "src/components/NavMenu";
import CustomIcon from "src/components/CustomIcon";
import { Helmet } from "react-helmet";
import NearMeOutlinedIcon from "@material-ui/icons/NearMe";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import qs from "query-string";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import { RouteComponentProps } from "react-router-dom";
import { Questionnaire_Url } from "config";
import {
  ChromeReaderModeOutlined,
  InsertEmoticon,
  NotificationImportantOutlined,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import { RootState } from "src/reducers";
import { PatientsState } from "src/reducers/patient";
import QRCode from "qrcode.react";

const useStyles = makeStyles((theme: Theme) => ({
  home: {
    padding: 6,
    paddingBottom: 62,
  },
  logo: {
    height: 36,
    marginBottom: 6,
  },
  banner: {
    marginBottom: 10,
  },
  icon: {
    color: "#00C4C2",
  },
  notice: {
    height: 30,
    padding: "0 10px",
    color: "orangered",
    backgroundColor: "lightyellow",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginBottom: 5,
  },
  noticeIcon: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  noticeText: {
    verticalAlign: "middle",
  },
  card: {
    backgroundColor: theme.custom.bgc,
    boxShadow: "1px 8px 7px 0px rgba(0, 132, 236, 0.15);",
    padding: 16,
    borderRadius: 4,
    "& + &": {
      marginTop: 5,
    },
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const CodeIcon: FC = () => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2405"
      width="30"
      height="30"
    >
      <path
        d="M129 102h321c16.569 0 30 13.431 30 30v322c0 16.569-13.431 30-30 30H129c-16.569 0-30-13.431-30-30V132C99 115.431 112.431 102 129 102z m70.692 70.877c-16.569 0-30 13.432-30 30v180.246c0 16.568 13.431 30 30 30h179.616c16.569 0 30-13.432 30-30V202.877c0-16.568-13.431-30-30-30H199.692z m56.92 57.07h65.776c16.568 0 30 13.431 30 30v66.106c0 16.569-13.432 30-30 30h-65.776c-16.568 0-30-13.431-30-30v-66.106c0-16.569 13.432-30 30-30zM129 540h321c16.569 0 30 13.431 30 30v322c0 16.569-13.431 30-30 30H129c-16.569 0-30-13.431-30-30V570c0-16.569 13.431-30 30-30z m70.692 70.877c-16.569 0-30 13.432-30 30v180.246c0 16.568 13.431 30 30 30h179.616c16.569 0 30-13.432 30-30V640.877c0-16.568-13.431-30-30-30H199.692z m56.92 57.07h65.776c16.568 0 30 13.431 30 30v66.106c0 16.569-13.432 30-30 30h-65.776c-16.568 0-30-13.431-30-30v-66.106c0-16.569 13.432-30 30-30zM574 102h321c16.569 0 30 13.431 30 30v322c0 16.569-13.431 30-30 30H574c-16.569 0-30-13.431-30-30V132c0-16.569 13.431-30 30-30z m70.692 70.877c-16.569 0-30 13.432-30 30v180.246c0 16.568 13.431 30 30 30h179.616c16.569 0 30-13.432 30-30V202.877c0-16.568-13.431-30-30-30H644.692z m56.92 57.07h65.776c16.568 0 30 13.431 30 30v66.106c0 16.569-13.432 30-30 30h-65.776c-16.568 0-30-13.431-30-30v-66.106c0-16.569 13.432-30 30-30z"
        fill="#00C4C2"
        p-id="2406"
      ></path>
      <path
        d="M544 541m30 0l31 0q30 0 30 30l0 31q0 30-30 30l-31 0q-30 0-30-30l0-31q0-30 30-30Z"
        fill="#00C4C2"
        p-id="2407"
      ></path>
      <path
        d="M834 541m30 0l31 0q30 0 30 30l0 31q0 30-30 30l-31 0q-30 0-30-30l0-31q0-30 30-30Z"
        fill="#00C4C2"
        p-id="2408"
      ></path>
      <path
        d="M544 831m30 0l31 0q30 0 30 30l0 31q0 30-30 30l-31 0q-30 0-30-30l0-31q0-30 30-30Z"
        fill="#00C4C2"
        p-id="2409"
      ></path>
      <path
        d="M635 632m30 0l31 0q30 0 30 30l0 139q0 30-30 30l-31 0q-30 0-30-30l0-139q0-30 30-30Z"
        fill="#00C4C2"
        p-id="2410"
      ></path>
      <path
        d="M834 723m30 0l31 0q30 0 30 30l0 139q0 30-30 30l-31 0q-30 0-30-30l0-139q0-30 30-30Z"
        fill="#00C4C2"
        p-id="2411"
      ></path>
      <path
        d="M925 861v31c0 16.569-13.431 30-30 30H756c-16.569 0-30-13.431-30-30v-31c0-16.569 13.431-30 30-30h139c16.569 0 30 13.431 30 30z"
        fill="#00C4C2"
        p-id="2412"
      ></path>
    </svg>
  );
};

const Home: FC<RouteComponentProps> = ({ history, location }) => {
  const classes = useStyles();
  const queryUrl = useMemo(() => qs.parse(location.search), [location.search]);
  const patientState = useSelector<RootState, PatientsState>(
    (state) => state.patient
  );
  const patient = queryUrl.patientcard
    ? patientState.patients.filter(
        (patient) => patient.patientcard === queryUrl.patientcard
      )[0]
    : patientState.patients[0];
  const [open, setOpen] = useState(false);
  const [tips, setTips] = useState("");
  return (
    <div className={classes.home}>
      <Helmet title="首页" />
      {/* <img
        src={require("src/assets/logo.png")}
        alt=""
        className={classes.logo}
      /> */}
      <Swiper className={classes.banner} />
      {patient && (
        <div>
          <Accordion key={patient.patientcard} className={classes.card}>
            <AccordionSummary
              expandIcon={<CodeIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ width: "56vw" }}>
                <span className={classes.cardTitle}>就诊人：</span>
                {patient.name} <br />
                {patient.patientcard}
              </Typography>
              <Button
                startIcon={<SwapHorizIcon />}
                color="primary"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  history.push("user/choose");
                }}
              >
                切换
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ margin: "0 auto" }}>
                <QRCode
                  id="qrCode"
                  value={patient.patientcard || ""}
                  size={160}
                  fgColor="#000"
                ></QRCode>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      <NavMenu
        title="门诊服务"
        navList={[
          {
            label: "当天挂号",
            icon: <CustomIcon type="icondaiban" color="primary" />,
            url: "/registered",
          },
          {
            label: "门诊缴费",
            icon: <CustomIcon type="iconcard" color="primary" />,
            url: "/outpatient",
          },
          {
            label: "预约挂号",
            icon: <CustomIcon type="iconyuyue" color="primary" />,
            // url: `/appointment`,
            onClick: () => {
              setOpen(true);
              setTips("请到“i荆门”APP进行挂号")
            },
          },
          {
            label: "报告查询",
            icon: <CustomIcon type="iconbingli" color="primary" />,
            url: "/report-query",
          },
        ]}
      />
      <NavMenu
        title="住院服务"
        navList={[
          {
            label: "住院充值",
            icon: <CustomIcon type="iconjine" color="primary" />,
            url: "/hospital/search",
          },
          {
            label: "住院清单",
            icon: <CustomIcon type="iconfapiao" color="primary" />,
            // url: "/hospital-expenses",
            onClick: () => {
              setOpen(true);
              setTips("功能完善中")
            },
          },
        ]}
      />
      <NavMenu
        title="智慧医院"
        navList={[
          {
            label: "医院介绍",
            icon: <CustomIcon type="iconyiyuan" color="primary" />,
            url: "/introduction",
          },
          {
            label: "科室信息",
            icon: (
              <i className={classes.icon}>
                <LocalHospitalOutlinedIcon />
              </i>
            ),
            url: "/department-info",
          },
          {
            label: "医院导航",
            icon: (
              <i className={classes.icon}>
                <NearMeOutlinedIcon />
              </i>
            ),
            onClick: () => {
              window.location.href =
                "http://api.map.baidu.com/marker?location=30.71866,112.57229&title=沙洋县人民医院&output=html";
            },
          },
          {
            label: "医院公告",
            icon: (
              <i className={classes.icon}>
                <NotificationImportantOutlined />
              </i>
            ),
            url: "/news?type=5",
          },
          {
            label: "政策信息",
            icon: (
              <i className={classes.icon}>
                <ChromeReaderModeOutlined />
              </i>
            ),
            url: "/news?type=2",
          },
          {
            label: "就医指南",
            icon: <CustomIcon type="iconyixueziliao" color="primary" />,
            url: "/news?type=3",
          },
          {
            label: "常见问题",
            icon: <CustomIcon type="iconsousuo1" color="primary" />,
            url: "/news?type=4",
          },
          {
            label: "满意度评价",
            icon: (
              <i className={classes.icon}>
                <InsertEmoticon />
              </i>
            ),
            onClick: () => {
              window.location.href = Questionnaire_Url;
            },
            url: "",
          },
        ]}
      />
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="dialog"
        open={open}
      >
        <DialogTitle id="dialog">{tips}</DialogTitle>
        <Button
          color="primary"
          onClick={() => {
            setOpen(false);
          }}
        >
          确认
        </Button>
      </Dialog>
    </div>
  );
};

export default Home;
