import React, { FC, useState } from "react";
import { Button, makeStyles, Theme } from "@material-ui/core";
import CustomIcon from "src/components/CustomIcon";
import NavMenu from "src/components/NavMenu";
import { useSelector } from "react-redux";
import { RootState } from "src/reducers";
import CreatePatientDialog from "src/components/CreatePatientDialog";
import { PatientType } from "src/typings/patient";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme: Theme) => ({
  user: {
    padding: 6,
    paddingBottom: 62,
  },
  header: {
    textAlign: "center",
    borderRadius: 4,
    padding: "32px 64px",
    backgroundColor: theme.custom.bgc,
    boxShadow: "1px 8px 7px 0px rgba(0, 132, 236, 0.15);",
    marginBottom: 10,
    "& p": {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      margin: "10px 0",
    },
  },
  addUser: {
    width: 68,
    height: 68,
  },
}));

const User: FC = () => {
  const classes = useStyles();

  const patients = useSelector<RootState, PatientType[]>(
    (state) => state.patient.patients
  );
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.user}>
      <Helmet title="个人中心" />
      <div className={classes.header}>
        <img
          src={require("src/assets/user_add.png")}
          className={classes.addUser}
          alt=""
        />
        {patients.length === 0 ? (
          <p>您还未添加就诊人，请先添加就诊人</p>
        ) : (
          <p>已添加{patients.length}位就诊人</p>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setOpen(true)}
        >
          添加就诊人
        </Button>
      </div>
      <NavMenu
        cols={3}
        title="个人服务"
        navList={[
          {
            label: "门诊缴费",
            icon: <CustomIcon type="iconcard" color="primary" />,
            url: "/outpatient",
            // future: true,
          },
          {
            label: "报告查询",
            icon: <CustomIcon type="iconbingli" color="primary" />,
            url: "/report-query",
          },
          {
            label: "就诊人列表",
            icon: <CustomIcon type="iconyibaoka-01" color="primary" />,
            url: "/patient",
          },
          {
            label: "挂号记录",
            icon: <CustomIcon type="iconjilu" color="primary" />,
            url: "/registered-record",
          },
          // {
          //   label: "电子健康卡",
          //   icon: <CustomIcon type="iconjilu" color="primary" />,
          //   url: "/health",
          //   future: true,
          // },
        ]}
      />
      <CreatePatientDialog open={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

export default User;
