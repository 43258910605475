import requestFn from "src/service";
import { ArchiveType } from "src/typings/archives";

// 创建档案
export function createArchives(data: ArchiveType) {
  return requestFn("/Patient/new", { method: "post", data });
}

// 查询档案
export function checkArchives(params: any,) {

  return requestFn("/Patient/patient", { params, CardID: '1111' });
}

// 更新档案
export function updateArchives(params: any) {
  return requestFn("/Patient/update", { params });
}

// 创建就诊人
export function createPatient(data: any) {
  return requestFn("/PatientManagement/create", { method: "post", data });
}

// 更新就诊人
export function updatePatient(data: any) {
  return requestFn("/PatientManagement/update", { method: "post", data });
}

// 就诊人列表
export function queryPatientList(params: any) {
  return requestFn("/PatientManagement/retrieve", { params });
}

// 删除就诊人
export function deletePatient(data: any) {
  return requestFn("/PatientManagement/delete", { method: "post", data });
}

// 绑卡通知
export function bindArchives(data: any) {
  return requestFn("/Patient/bind", { method: "POST" , data });
}

// 取消绑卡通知
export function cancelArchives(data: any) {
  return requestFn("/Patient/cancel", { method: "POST" , data });
}