import { extend } from "umi-request";
import { PREFIX_API_URL } from "config";
import SnackbarUtils from "src/utils/SnackbarUtils";
import getCommonRequestParams from "src/utils/commonParams";
import shaSign from "src/utils/sign";

const requestFn = extend({
  prefix: PREFIX_API_URL,
});

requestFn.interceptors.request.use((url, options) => {
  // console.log( options.method !== "get");
  const data =
    options.method !== "get"
      ? shaSign({ ...getCommonRequestParams(), ...options.data })
      : shaSign({ ...getCommonRequestParams(), ...options.params });
  // console.log(data);
  const newOptions = {
    ...options,
    ...(options.method !== "get" ? { data } : { params: data }),
  };

  return {
    url,
    options: newOptions,
  };
});

requestFn.interceptors.response.use(async (response) => {
  if (response.status && response.status != 200) {
    //有 status 但status 不是200
    SnackbarUtils.error(`${response.status}-${response.statusText}`);
    return;
  }
  const res = await response.json();
  // console.log(res);
  if (res.code !== 0) {
    // if(res.code === 4) {
    //   SnackbarUtils.error('业务处理失败！');
    //   return;
    // }
    if (response.url.includes("/pay")) {
      return res.data;
    }
    SnackbarUtils.error(res.msg);
    return;
  }
  if (response.url.includes("/Patient/patient")) {
    return res.data;
  }
  // 支付接口状态码为1是正常
  if (/\/Payment\//.test(response.url)) {
    if (res.data.ThirdCode !== 1) {
      SnackbarUtils.error(res.data.ThirdMsg);
      return;
    }
  } else if (
    // 其他接口0为正常
    res.data.hasOwnProperty("ThirdCode") &&
    res.data.ThirdCode !== 0
  ) {
    SnackbarUtils.error(res.data.ThirdMsg);
    return;
  }
  return res.data;
});

/**
 * 封装请求函数
 */
export default requestFn;
