import { combineReducers } from "redux";
import patientReducer from "./patient";
import userReducer from "./user";

const rootReducer = combineReducers({
  user: userReducer,
  patient: patientReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
