import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  CircularProgress,
  Backdrop,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import NavigationBar from "./components/NavigationBar";
import AuthRoute from "./components/AuthRoute";

import Home from "./pages/Home";
import User from "./pages/User";
import Login from "./pages/Login";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientAction } from "./actions/patient";
import { RootState } from "./reducers";
import { UserState } from "./reducers/user";

const ChoosePatient = lazy(() => import("./pages/User/choose"));
const Patient = lazy(() => import("./pages/Patient"));
const Manual = lazy(() => import("./pages/Manual"));
const Pay = lazy(() => import("./pages/Pay"));
const YibaoConfirm = lazy(() => import("./pages/Pay/yibaoConfirm"));
const PaySuccess = lazy(() => import("./pages/Pay/success"));
const Appointment = lazy(() => import("./pages/Appointment"));
const AppointmentDoctor = lazy(() => import("./pages/Appointment/Doctor"));
const Registered = lazy(() => import("./pages/Registered"));
const RegisteredDoctor = lazy(() => import("./pages/Registered/Doctor"));
const Outpatient = lazy(() => import("./pages/Outpatient"));
const RechargeInHospital = lazy(() => import("./pages/RechargeInHospital"));
const HospitalSearch = lazy(() => import("./pages/RechargeInHospital/search"));
const HospitalExpenses = lazy(() => import("./pages/HospitalExpenses"));
const ReportQuery = lazy(() => import("./pages/ReportQuery"));
const HealthCardList = lazy(() => import("./pages/HealthCardList"));
const HealthCardDetail = lazy(() => import("./pages/HealthCardList/Detail"));
const HealthCardBind = lazy(() => import("./pages/HealthCardList/Bind"));
const RegisteredRecord = lazy(() => import("./pages/RegisteredRecord"));
const Introduction = lazy(() => import("./pages/Introduction"));
const BarScan = lazy(() => import("./pages/BarScan"));
const DepartmentInfo = lazy(() => import("./pages/DepartmentInfo"));
const DoctorInfo = lazy(() => import("./pages/DoctorInfo"));
const News = lazy(() => import("./pages/News"));
const NewsDetail = lazy(() => import("./pages/News/detail"));
const Guide = lazy(() => import("./pages/Guide"));


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

function App() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector<RootState, UserState>((state) => state.user);

  useEffect(() => {
    if (!user) return;
    dispatch(
      fetchPatientAction({
        openid: user.openid,
        userid: user.userid,
      })
    );
  }, [dispatch, user]);

  return (
    <Suspense
      fallback={
        <Backdrop open className={classes.backdrop}>
          <CircularProgress />
        </Backdrop>
      }
    >
      <BrowserRouter>
        <NavigationBar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/login" exact component={Login} />
          <AuthRoute path="/user" exact component={User} />
          <AuthRoute path="/patient" exact component={Patient} />
          <AuthRoute path="/manual" exact component={Manual} />
          <Route path="/pay" exact component={Pay} />
          <Route path="/yibaoConfirm" exact component={YibaoConfirm} />
          <AuthRoute path="/pay/success" exact component={PaySuccess} />
          <AuthRoute path="/appointment" exact component={Appointment} />
          <AuthRoute
            path="/appointment/doctor"
            exact
            component={AppointmentDoctor}
          />
          <AuthRoute path="/registered" exact component={Registered} />
          <AuthRoute
            path="/registered/doctor"
            exact
            component={RegisteredDoctor}
          />
          <AuthRoute
            path="/registered-record"
            exact
            component={RegisteredRecord}
          />
          <AuthRoute path="/outpatient" exact component={Outpatient} />
          <Route
            path="/hospital/recharge"
            exact
            component={RechargeInHospital}
          />
          <AuthRoute path="/hospital/search" exact component={HospitalSearch} />
          <AuthRoute
            path="/hospital-expenses"
            exact
            component={HospitalExpenses}
          />
          <AuthRoute path="/report-query" exact component={ReportQuery} />
          <AuthRoute path="/user/choose" exact component={ChoosePatient} />
          <AuthRoute path="/health" exact component={HealthCardList} />
          <AuthRoute path="/health/:id" exact component={HealthCardDetail} />
          <AuthRoute path="/health/bind" exact component={HealthCardBind} />
          <Route path="/introduction" exact component={Introduction} />

          <Route path="/barscan" exact component={BarScan} />
          <Route path="/department-info" exact component={DepartmentInfo} />
          <Route path="/doctor-info" exact component={DoctorInfo} />
          <Route path="/news" exact component={News} />
          <Route path="/news/detail" exact component={NewsDetail} />
          <Route path="/guide" exact component={Guide} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
