import { Action } from "redux";

export const LOGIN = "LOGIN";
export type LOGIN = typeof LOGIN;

export type LoginPayloadType = {
  access_token: string;
  openid: string;
  userid: number;
};

export interface loginAction extends Action {
  type: LOGIN;
  payload: LoginPayloadType;
}

export const login = (payload: LoginPayloadType): loginAction => {
  return {
    type: LOGIN,
    payload,
  };
};
