import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Grid,
} from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { CardOptions } from "src/utils/commonOptions";
import { CardType } from "src/typings/common";
import { checkArchives, createPatient } from "src/service/api/Patient";
import { sendVerifyCode } from "src/service/api/BaseInfo";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/reducers";
import { UserState } from "src/reducers/user";
import { addPatientAction } from "src/actions/patient";
import SnackbarUtils from "src/utils/SnackbarUtils";

interface CreatePatientDialogProps {
  open: boolean;
  handleClose: () => void;
}
// CardNo   CardNo
export type FormValues = {
  CardType: CardType;
  CardNo: string;
  PatientName?: string;
  CardID?: string;
  Mobile?: string;
  VerifyCode?: string | number;
};

const CreatePatientDialog: FC<CreatePatientDialogProps> = (props) => {
  const { open, handleClose } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector<RootState, UserState>((state) => state.user);
  const {
    register,
    control,
    handleSubmit,
    errors,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      CardType: 1,
      CardID: '1111111',
      Mobile: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const [cardNumberTips, setCardNumberTips] = useState("请输入身份证号");
  const [cardNumberTop, setCardNumberTop] = useState("身份证号");
  const [veri, setVeri] = useState(false); // 是否显示获取验证码
  const onCardTypeClickEvent = useCallback(
    (e: any) => {
      // console.log(CardOptions);
      var sName = "";
      for (var index = 0; index < CardOptions.length; index++) {
        if (CardOptions[index].value === e.target.value)
          sName = CardOptions[index].label;
      }
      console.log(sName);
      setCardNumberTips(`请输入${sName}号`);
      setCardNumberTop(`${sName}号`);
    },
    []);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!user || loading) return;
      setLoading(true);
      const checkRes = await checkArchives({ ...values, CardID: '111111' });
      setLoading(false);
      if (checkRes.ThirdCode === -1 || checkRes.ThirdCode === -4) {
        history.push(
          "/manual",
          Object.assign(
            values,
            values.CardType === 1
              ? {
                IDType: values.CardType,
                IDNo: values.CardNo,
              }
              : null
          )
        );
      } else if (checkRes.ThirdCode === 0) {
        const doc = checkRes.Patient[0];
        console.log(doc, 1090);

        setLoading(true);
        // 判断手机号是否为空，空的去医院人工窗口补充
        if (!doc.Mobile) {
          SnackbarUtils.error('手机号为空，请前往人工窗口添加手机号后再操作！')
          return
        } else if (!!doc.Mobile && !veri) {
          const sendSms = await sendVerifyCode({ Mobile: doc.Mobile })
          if (!sendSms) return;
          setVeri(true);
          setLoading(false);
          SnackbarUtils.info('请填入获取到的短信验证码');
        } else {
          // 否则直接根据档案信息创建就诊人
          console.log(values)
          const res = await createPatient({
            openid: user.openid,
            userid: String(user.userid),
            idcard: doc.IDNo,
            hid: doc.PatientId,
            nickname: doc.PatientName,
            phone: doc.Mobile,
            sex: doc.Sex,
            birthday: dayjs(doc.birthday).format("YYYY-MM-DD HH:mm:ss"),
            patientcard: doc.CardNo,
            cardtype: doc.CardType,
            VerifyCode: values.VerifyCode,
          });

          setLoading(false);
          setVeri(false);
          if (!res) return;
          dispatch(
            addPatientAction({
              openid: user.openid,
              userid: res.userid,
              idcard: res.idcard,
              name: res.nickname,
              phone: res.phone,
              sex: res.sex,
              birthday: dayjs(res.birthday).format("YYYY-MM-DD HH:mm:ss"),
              hid: res.hid,
              patientcard: res.patientcard,
              patientid: +res.patientid,
              cardtype: res.cardtype,
            })
          );
          SnackbarUtils.success("创建成功");
          handleClose();
        }
      } else {
        SnackbarUtils.error(checkRes.ThirdMsg);
      }
    },
    [dispatch, handleClose, history, loading, user]
  );

  return (
    <Dialog onClose={handleClose} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>查询档案</DialogTitle>
        <DialogContent>
          <Controller
            name="CardType"
            control={control}
            rules={{ required: "请选择卡类型" }}
            as={
              <TextField
                select
                label="卡类型"
                fullWidth
                error={!!errors.CardType}
                helperText={errors.CardType?.message} onClick={onCardTypeClickEvent}
              >
                {CardOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            }
          />
          <TextField
            name="CardNo"
            fullWidth
            margin="normal"
            label={cardNumberTop}
            inputRef={register({ required: cardNumberTips })}
            error={!!errors.CardNo}
            helperText={errors.CardNo?.message}
          />
          {watch("CardType") === 1 && (
            <TextField
              name="PatientName"
              fullWidth
              margin="normal"
              label="姓名"
              inputRef={register({
                required:
                  watch("CardType") === 1
                    ? "当卡类型为二代身份证时必填"
                    : false,
              })}
              error={!!errors.PatientName}
              helperText={errors.PatientName?.message}
            />
          )}
          {/* {watch("CardType") === 1 && !!veri && ( */}
          {!!veri && (
            <Grid container alignItems="center">
              <Grid item>
                <TextField
                  name="VerifyCode"
                  label="验证码"
                  inputRef={register({
                    required: "请输入验证码"
                  })}
                  fullWidth
                  error={!!errors.VerifyCode}
                  helperText={errors.VerifyCode?.message}
                />
              </Grid>
            </Grid>
          )}
          {watch("CardType") === 2 && (
            <TextField
              name="CardID"
              fullWidth
              margin="normal"
              label="校验码"
              inputRef={register({
                required:
                  watch("CardType") === 2
                    ? "当卡类型为院内就诊卡时必填"
                    : false,
              })}
              error={!!errors.CardID}
              helperText={errors.CardID?.message}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            确定
          </Button>
          <Button variant="contained" onClick={() => {
            setVeri(false);
            setLoading(false);
            handleClose()
          }}>
            取消
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreatePatientDialog;
