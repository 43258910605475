import { CardType, IDType } from "src/typings/common";

type Option<T = any> = {
  label: string;
  value: T;
};

type IDOpionsType = Option<IDType>;
export const IDOtions: IDOpionsType[] = [
  {
    label: "二代身份证",
    value: 1,
  },
  {
    label: "院内就诊卡",
    value: 99,
  },
];

type CardOptionsType = Option<CardType>;
export const CardOptions: CardOptionsType[] = [
  {
    label: "二代身份证",
    value: 1,
  },
  {
    label: "院内就诊卡",
    value: 2,
  },
  // {
  //   label: "电子健康卡",
  //   value: 3,
  // },
  // {
  //   label: "社保卡",
  //   value: 4,
  // },
];
