import { Close } from "@material-ui/icons";
import { useSnackbar, VariantType, WithSnackbarProps } from "notistack";
import React from "react";

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

export default {
  success(msg: string,closedTime=5000) {
    this.toast(msg, "success");
  },
  warning(msg: string,closedTime=5000) {
    this.toast(msg, "warning");
  },
  info(msg: string,closedTime=5000) {
    this.toast(msg, "info");
  },
  error(msg: string,closedTime=5000) {
    this.toast(msg, "error");
  },
  toast(msg: string, variant: VariantType = "default",closedTime=5000) {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: closedTime,
      preventDuplicate: true,
      action: <Close onClick={() => useSnackbarRef.closeSnackbar()} />,
    });
  },
};
