import { Action } from "redux";
import { PatientType } from "src/typings/patient";
import { ThunkAction } from "redux-thunk";
import { RootState } from "src/reducers";
import { queryPatientList } from "src/service/api/Patient";

export const GET_PATIENT = "GET_PATIENT";
export type GET_PATIENT = typeof GET_PATIENT;

export const SET_PATIENT = "SET_PATIENT";
export type SET_PATIENT = typeof SET_PATIENT;

export const ADD_PATIENT = "ADD_PATIENT";
export type ADD_PATIENT = typeof ADD_PATIENT;

export const DEL_PATIENT = "DEL_PATIENT";
export type DEL_PATIENT = typeof DEL_PATIENT;

export const UPDATE_PATIENT = "UPDATE_PATIENT";
export type UPDATE_PATIENT = typeof UPDATE_PATIENT;

interface GetPatientType extends Action {
  type: GET_PATIENT;
}

export const getPatientAction = (): GetPatientType => {
  return {
    type: GET_PATIENT,
  };
};
interface SetPatientType extends Action {
  type: SET_PATIENT;
  payload?: PatientType[];
}

export const setPatientAction = (payload?: PatientType[]): SetPatientType => {
  return {
    type: SET_PATIENT,
    payload,
  };
};

export const fetchPatientAction = (payload: {
  userid: number;
  openid: string;
}): ThunkAction<void, RootState, void, GetPatientType | SetPatientType> => {
  return async (dispatch) => {
    dispatch(getPatientAction());
    const res = await queryPatientList(payload);
    dispatch(setPatientAction(res && res.patients));
  };
};

interface AddPatientType extends Action {
  type: ADD_PATIENT;
  payload: PatientType;
}

export const addPatientAction = (payload: PatientType): AddPatientType => {
  return {
    type: ADD_PATIENT,
    payload,
  };
};

interface DelPatientType extends Action {
  type: DEL_PATIENT;
  payload: number;
}

export const delPatientAction = (payload: number): DelPatientType => {
  return {
    type: DEL_PATIENT,
    payload,
  };
};

interface UpdatePatientType extends Action {
  type: UPDATE_PATIENT;
  payload: Partial<PatientType>;
}

export const updatePatientAction = (
  payload: Partial<PatientType>
): UpdatePatientType => {
  return {
    type: UPDATE_PATIENT,
    payload,
  };
};

export type PatientAction =
  | GetPatientType
  | SetPatientType
  | AddPatientType
  | DelPatientType
  | UpdatePatientType;
