import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { zhCN } from "@material-ui/core/locale";
import { CircularProgress } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import dayjs_zhCN from "dayjs/locale/zh-cn";
import "./index.css";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer, { RootState } from "./reducers";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtils";
import "react-infinite-calendar/styles.css";

// import eruda from "eruda";

dayjs.locale(dayjs_zhCN);

// material-ui配置相关
const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#00C4C2",
        contrastText: "#fff",
      },
    },
    custom: {
      bgc: "rgba(255, 253, 246, 0.6)",
    },
  },
  zhCN
);

// eruda.init();
// 兼容ios 微信支付url问题
if (!window.initUrl) {
  window.initUrl = window.location.href;
}

// redux相关配置
const persistConfig: PersistConfig<RootState> = {
  key: "syrm",
  storage,
};
const persist = persistReducer(persistConfig, rootReducer);
const store = createStore(persist, applyMiddleware(thunk));
// @ts-ignore
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<CircularProgress />}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider hideIconVariant>
            <SnackbarUtilsConfigurator />
          </SnackbarProvider>
          <App />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
