import { FC, useEffect, useState } from "react";
import qs from "query-string";
import { Link, RouteComponentProps } from "react-router-dom";
import { getWechatUser } from "src/service/api/Wechat";
import { useDispatch } from "react-redux";
import { login } from "src/actions/user";
import React from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import {urlReg} from "../../utils/reg";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const Login: FC<RouteComponentProps> = ({ history, location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function redirect() {
      const query = qs.parse(location.search);
      if (query.code) {
        setLoading(true);
        const callBackUri = typeof query.callBackUri === "string" ? query.callBackUri : "/";
        if (urlReg.test(callBackUri)) { // 带code跳转到外部路由
          window.location.replace(`${query.callBackUri}?code=${query.code}`)
        } else { //使用code后再跳到内部路由
          const res = await getWechatUser({code: query.code});
          setLoading(false);
          if (!res) return;
          dispatch(login(res));  //数据保存起来
          history.replace(callBackUri || "/");
        }
      } else {
        //没有code
        history.replace("/");
      }
    }
    redirect();
  }, [dispatch, history, location.search]);
  return (
    <div className={classes.root}>
      <Helmet title="登录..." />
      {loading ? (
        <CircularProgress />
      ) : (
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/"
          replace
        >
          回到首页
        </Button>
      )}
    </div>
  );
};

export default Login;
