import React, { FC, useState, useCallback, useEffect } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
} from "@material-ui/core";
import CustomIcon from "../CustomIcon";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles({
  navgation: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 1
  },
});

const showBarUrl = ["/", "/user"];

/**
 * @name 底部导航栏
 */
const NavigationBar: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState(location.pathname || "/");

  useEffect(() => {
    const unlistenFn = history.listen((info) => {
      setValue(info.pathname);
    });
    return () => unlistenFn();
  }, [history]);

  const onChange = useCallback(
    (_, v) => {
      history.replace(v);
    },
    [history]
  );

  return showBarUrl.includes(value) ? (
    <BottomNavigation
      value={value}
      onChange={onChange}
      showLabels
      className={classes.navgation}
    >
      <BottomNavigationAction
        label="首页"
        value="/"
        icon={<CustomIcon type="iconshouye" />}
      />
      <BottomNavigationAction
        label="个人中心"
        value="/user"
        icon={<CustomIcon type="icongerenzhongxin" />}
      />
    </BottomNavigation>
  ) : null;
};

export default NavigationBar;
